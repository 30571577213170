import React from "react"
import { useStaticQuery, qraphql, Link } from 'gatsby'

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import './osaifu.scss'

const Osaifu = () => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "osaifu.png" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="RTS - Works" />
      <div className='rts'>
        <h1><Link to='/works'>Works</Link><span>|</span>おさいふ</h1>
        <p>ふたりでつける家計簿アプリ</p>
        <a href='https://osaifu.zatsuzen.com' className='osaifu' target='_blank' rel='noopener'>
          <div className='title'>おさいふ</div>
        </a>
        <div className='image'>
          <div className='device'>
            <div className='screenshot'><img src={image.childImageSharp.fluid.src} /></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Osaifu